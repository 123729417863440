<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="detail-box">
    <!-- 融资信息-->
    <template>
      <h3 class="title">
        融资信息
      </h3>
      <ul class="basic-information clear">
        <li class="flex-box">
          <span class="li-label">金融机构：</span><span class="flex1">{{ detailList.prodInfo.proFundName }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">融资到期日：</span><span class="flex1">{{ detailList.loanMaxdateDay }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">融资期限：</span><span class="flex1">{{ detailList.prodInfo.proFundDaterange.replace(',','-') }}天</span>
        </li>
        <li class="flex-box">
          <span class="li-label">融资比例：</span><span class="flex1">{{ detailList.prodInfo.creditRatio }}%</span>
        </li>
        <li class="flex-box">
          <span class="li-label">单笔融资限额：</span><span class="flex1">{{ detailList.prodInfo.singleFinanceAmount|formatMoney }}元</span>
        </li>
        <li class="flex-box">
          <span class="li-label">收款账户：</span><span class="flex1">{{ detailList.recvAccountNo }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">贷款用途：</span><span class="flex1">{{ $code('loanUse',detailList.loanUse) }}</span>
        </li>
      </ul>
    </template>
    <!-- 信链信息-->
    <template>
      <h3 class="title">
        信链信息
      </h3>
      <ul class="basic-information clear">
        <li class="flex-box">
          <span class="li-label">信链编号：</span><span class="flex1">{{ detailList.transferInfoRes.echainApplyNo }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">开立方：</span><span class="flex1">{{ detailList.transferInfoRes.recvCmpName }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">统一社会信用代码：</span><span class="flex1">{{ detailList.transferInfoRes.recvCmpUnicode }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">信链到期日：</span><span class="flex1">{{ detailList.transferInfoRes.echainValidate|date }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">转让方：</span><span class="flex1">{{ detailList.transferInfoRes.payCmpName }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">统一社会信用代码：</span><span class="flex1">{{ detailList.transferInfoRes.payCmpUnicode }}</span>
        </li>
      </ul>
    </template>
    <!-- 选择融资发票-->
    <template>
      <h3 class="title">
        选择融资发票
      </h3>
      <div class="basic-information">
        <el-table :border="true" class="br-table" empty-text="暂无数据" :data="detailList.payableBillInfo">
          <el-table-column label="应收账款编号" prop="billNo" />
          <el-table-column label="企业名称(买方)" prop="buyCmpName" />
          <el-table-column label="应收账款金额" prop="billAmt">
            <template slot-scope="scope">
              {{ scope.row.billAmt|formatMoney }}
            </template>
          </el-table-column>
          <el-table-column label="已收金额" prop="receAmt">
            <template slot-scope="scope">
              {{ scope.row.receAmt|formatMoney }}
            </template>
          </el-table-column>
          <el-table-column label="应收账款余额" prop="payableAmt">
            <template slot-scope="scope">
              {{ scope.row.payableAmt|formatMoney }}
            </template>
          </el-table-column>
          <el-table-column label="应收账款到期日" prop="acctDate">
            <template slot-scope="scope">
              {{ scope.row.acctDate|date }}
            </template>
          </el-table-column>
        </el-table>
        <el-table :border="true" class="br-table" empty-text="暂无数据" :data="detailList.invoiceList">
          <el-table-column label="发票代码" prop="invoiceCode" />
          <el-table-column label="发票号码" prop="invoiceNo" />
          <el-table-column label="开票日期" prop="invoiceDate" min-width="100">
            <template slot-scope="scope">
              {{ scope.row.invoiceDate|date }}
            </template>
          </el-table-column>
          <el-table-column label="发票金额(含税)" prop="invoiceAmt" min-width="120">
            <template slot-scope="scope">
              {{ scope.row.invoiceAmt|formatMoney }}
            </template>
          </el-table-column>
          <el-table-column label="上传发票" prop="billImages">
            <template slot-scope="scope">
              <div v-if="scope.row.billImages&&scope.row.billImages.length>0" class="down-file" @click="openFile(scope.row.billImages[0].mongoId)">
                {{ scope.row.billImages[0].oriFileName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="其他附件" prop="otherAttachs">
            <template slot-scope="scope">
              <div
                v-if="scope.row.otherAttachs&&scope.row.otherAttachs.length>0"
                class="down-file"
                @click="openFile(scope.row.otherAttachs[0].mongoId)"
              >
                {{ scope.row.otherAttachs[0].oriFileName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="合同信息" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" type="success" class="table-detail-button" @click="showDialog(scope.row)">
                查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="money-box">
          <span class="li-label">融资金额：</span><span class="flex1"><span
            class="red-color"
          >{{ detailList.appAmt|formatMoney }}</span> 元</span>
        </div>
      </div>
    </template>
    <!-- 预计融资费用-->
    <template>
      <div class="title">
        <el-tooltip effect="dark" placement="right">
          <template slot="content">
            <div class="flex-box">
              <div class="" style="width: 30px; ">
                说明:
              </div>
              <div class="flex1" style="">
                1. &nbsp;融资利息、保理服务费、平台服务费、杂费在放款时一次性收取。<br>2. 各项费用计算公式：<br>&nbsp;&nbsp;&nbsp;&nbsp;融资利息=融资本金*融资利率*融资天数/360，<br>&nbsp;&nbsp;&nbsp;&nbsp;平台服务费=融资本金*平台服务费率*融资天数/360，最低XX元/笔。
              </div>
            </div>
          </template>
          <h3 class="tips-txt">
            预计融资费用<i
              class="br-iconfont iconfont icon-gantanhao"
              style="margin-left: 6px;font-size: 14px;color: #409EFF"
            />
          </h3>
        </el-tooltip>
      </div>
      <div class="basic-information">
        <el-table :border="true" class="br-table" empty-text="暂无数据" :data="detailList?detailList.feeInfo:[]">
          <el-table-column label="费用名称" prop="costName" />
          <el-table-column label="费用标准" prop="costStandard">
            <template
              slot-scope="scope"
            >
              {{ scope.row.costStandard }}{{ costUnitMap[scope.row.costUnit] }}
            </template>
          </el-table-column>
          <el-table-column label="费用金额" prop="costNum">
            <template slot-scope="scope">
              {{ scope.row.costNum|formatMoney }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <!-- 企业基础信息-->
    <template>
      <div v-if="type==2" class="title">
        <el-tooltip effect="dark" placement="right">
          <template slot="content">
            <div class="flex-box">
              <div class="" style="width: 60px; ">
                上传说明：
              </div>
              <div class="flex1" style="">
                1. 支持jpg/png、pdf、doc/docx、xls/xlsx、zip/rar格式；<br>2. 每个证件最多上传2张，公司章程和财务报表最多可上传6份文件，其他文件最多上传10份，每份大小不得超过5MB；<br>3. 所有文件非必填。<br>
              </div>
            </div>
          </template>
          <h3 class="tips-txt">
            企业基础信息<i
              class="br-iconfont iconfont icon-gantanhao"
              style="margin-left: 6px;font-size: 14px;color: #409EFF"
            />
          </h3>
        </el-tooltip>
      </div>
      <h3 v-if="type==1" class="title">
        企业基础信息
      </h3>
      <ul class="file-box clear">
        <li v-for="(i,t) in files" :key="t" class="flex-box">
          <div class="li-label">
            {{ fileName[i] }}：
          </div>
          <div class="flex1 file">
            <span v-if="detailList.fileMap && detailList.fileMap[i]">
              <span v-for="(item,index) in detailList.fileMap[i]" :key="index">
                <span class="txt" @click="openFile(item.fastdfsId)">{{ item.oriFileName }}</span><span v-if="index!=detailList.fileMap[i].length-1"> , </span>
              </span>
            </span>
          </div>
        </li>
      </ul>
    </template>
    <!-- 平台审核-->
    <template v-if="type==2">
      <h3 class="title">
        平台审核
      </h3>
      <ul class="basic-information clear">
        <li class="flex-box" style="width: 100%">
          <el-form ref="Forms" :model="formParams" size="small" label-width="100px" :rules="rules" style="width: 100%" @submit.native.prevent>
            <el-form-item
              label="审核结果："
              label-width="130px"
              class="br-form-item-label"
              prop="approvalResult"
            >
              <el-radio-group
                v-model="formParams.approvalResult"
                class="br-radio"
                @change="onApprovalResultChange"
              >
                <!-- v-if="item.VARIABLE_CODE!=='approve_back'" -->
                <el-radio
                  v-for="(item,index) in handleAuthRadio(resourceByTaskList)"

                  :key="index"
                  :label="item.VARIABLE_CODE"
                >
                  {{ item.VARIABLE_NAME }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              v-if="formParams.approvalResult=='approve_back'"
              label="回退至："
              label-width="130px"
              class="br-form-item-label"
              prop="backResult"
            >
              <el-select v-model="formParams.backResult" placeholder filterable class="br-select">
                <el-option
                  v-for="(item,index) in backNodeList"
                  :key="item"
                  :value="index"
                  :label="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="审核意见："
              label-width="130px"
              class="br-form-item-label"
              prop="approvalSuggestion"
            >
              <el-input
                v-model="formParams.approvalSuggestion"
                type="textarea"
                class="br-input"
                :autosize="{ minRows: 2, maxRows: 6}"
              />
            </el-form-item>
          </el-form>
        </li>
      </ul>
      <div class="btn-box">
        <el-button type="primary" class="submit-btn" @click="submitData">
          提交
        </el-button>
        <el-button class="cancel-btn" @click="cancelData">
          取消
        </el-button>
      </div>
    </template>

    <el-dialog title="合同信息" :visible.sync="dialogTableVisible" class="echain-dialog-contact">
      <el-table v-loading="dialogLoading" :data="contractData">
        <el-table-column type="index" label="序号" width="50" />
        <el-table-column prop="contractNo" label="合同编码" />
        <el-table-column prop="contractDate" label="合同签订日期" min-width="100" />
        <el-table-column prop="contractAmt" label="合同金额">
          <template slot-scope="scope">
            {{ scope.row.contractAmt|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="合同附件">
          <template slot-scope="scope">
            <div
              v-if="scope.row.contractAttachs.length>0"
              class="down-file"
              @click="openFile(scope.row.contractAttachs[0].mongoId)"
            >
              {{ scope.row.contractAttachs[0].oriFileName }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: () => ('1')
    },
    detailList: {
      type: Object,
      default: () => ({})
    }

  },
  data() {
    return {
      fullscreenLoading: false,
      // 费用收取方式 00：%、01：笔、02：年化率
      costUnitMap: {
        '00': '%',
        '01': '元/笔',
        '02': '%年化率'
      },
      files: ['01', '02', '03', '04', '05', '06', '07', '08', '09'],
      fileName: {
        '01': '营业执照',
        '02': '税务登记照',
        '03': '组织机构代码证',
        '04': '统一社会信用代码证',
        '05': '开户许可证',
        '06': '法定代表人身份证',
        '07': '公司章程',
        '08': '财务报表',
        '09': '其他文件'
      }
    }
  },
  computed: {
    FileUrl() {
      return this.$store.getters.getDictionaryItem('FILE_PREFIX_URL')
    },
    echainState() {
      return this.$store.getters.getDictionaryItem('ECHAIN_STATE')
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {

    }
  }
}
</script>
