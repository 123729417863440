<template>
  <div>
    <el-tabs v-model="tabVal">
      <el-tab-pane v-if="type==1" label="融资详情" name="1">
        <CommonDetail :type="type" :detail-list="detailList" />
      </el-tab-pane>
      <el-tab-pane v-if="type==2" label="审核融资申请" name="2">
        <CommonDetail :type="type" :detail-list="detailList" />
      </el-tab-pane>
      <el-tab-pane label="审核历史" name="3">
        <!-- <History :id="echainFinanceNo" /> -->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { selectFinanceInfo } from '@/api/tradeFinancing.js'
import CommonDetail from './components/common.vue'
export default {
  components: {
    CommonDetail
  },
  data() {
    return {
      tabVal: 1,
      type: 1,
      echainFinanceNo: '',
      detailList: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.type = this.$route.query.type // 1-详情 2-申请
      this.echainFinanceNo = this.$route.query.echainFinanceNo // 历史要
      this.tabVal = this.$route.query.type
      this.getDetail()
    },
    getDetail() {
      selectFinanceInfo({ id: this.$route.query.id }, res => {
        if (res.code === 200) {
          this.detailList = res.data
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    }
  }
}
</script>
